import React from "react";
import NotFoundPageLayout from "../layouts/NotFoundPageLayout";
import Layout from "../hoc/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";

const NotFoundPage = () => {
  return (
    <Layout title="Page non trouvée">
      <Header />
      <NotFoundPageLayout />
      <Footer />
    </Layout>
  );
};

export default NotFoundPage;
