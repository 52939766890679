import React from "react";
import useTranslation from "../hooks/useTranslation";

const NotFoundPageLayout = () => {
  const {t} = useTranslation();
  return (
    <div className="max-w-[1366px] h-[100vh] mx-auto pt-[109px] px-[30px]">
      <div className="h-full flex flex-col sm:flex-row items-center gap-8 justify-center sm:justify-between">
        <div>
          <p className="font-MyriadPro text-2xl font-semibold">{t("404 error")}</p>
          <h2 className="font-MyriadPro text-[48px] sm:text-[68px] font-semibold">
            {t("Page non trouvée")}
          </h2>
          <p className="text-[22px]">
            {t("Désolé, la page que vous recherchez n'existe pas.")}
          </p>
        </div>
        <div className="">
          <img
            src="/svg/404.svg"
            className="w-[395px] h-[428px]"
            alt="404 page"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPageLayout;